import { components, constants } from 'cng-web-lib'
import React, { useEffect, useState, useContext, useCallback } from 'react'
import PartyMasterApiUrls from '../../../apiUrls/PartyMasterApiUrls'
import { Box, Grid, IconButton, InputAdornment, Typography } from '@material-ui/core'
import { NaStateCodeAutoCompleteField } from 'src/components/na'
import { NaPartyMasterAutoCompleteField } from 'src/components/na/autocomplete/codemaintenance'
import { useFormContext } from "react-hook-form";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import CngSection from '../../../components/cngcomponents/CngSection'
import TemplateSelectDialog from '../../../components/aciacehighway/TemplateSelectDialog'
import _ from 'lodash'
import { FileForUserGetPartyId, FileForUserGetUserDetails } from 'src/common/FileForUserCommon'
import { ACIHighwayTranslationContext } from '../contexts/ACIHighwayTranslationContext'
import { NaAcePartyMasterAutoCompleteField } from 'src/components/na/autocomplete/codemaintenance'
import * as InputSource from 'src/common/InputSource'

const {
  form: {
    field: {
      CngCheckboxField,
      CngTextField,
      CngCountryAutocompleteField,
      CngCodeMasterAutocompleteField,
    },
  },
  CngGridItem,
  table: { useFetchCodeMaintenanceLookup },
} = components

const {
  filter: { LIKE, EQUAL, IN }
} = constants

const DEFAULT_INITIAL_VALUES = Object.freeze({
  partyType: "",
  businessNo: "",
  partyName: "",
  address: "",
  city: "",
  postalCode: "",
  countryCode: "",
  stateCode: "",
  adminName: "",
  adminContact: "",
  partyDropDown: "",
  version: "",
  saveFlag: false,
  partyIdentification: "",
  phoneNo: "",
  faxNo: "",
  email: ""
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
}

function Fields({ disabled, shouldHideMap, cargoTypes, index }) {
  const { setValue, reset, watch, trigger } = useFormContext();

  const getFieldName = useCallback((field) => {
    return typeof index === 'number' ? `cargoParties.${index}.${field}` : field
  }, [])

  const saveFlag = watch(getFieldName('saveFlag'));
  const countryCode = watch(getFieldName('countryCode'));
  const stateCode = watch(getFieldName('stateCode'));
  const partyType = watch(getFieldName('partyType'))

  const [templateDialog, setTemplateDialog] = useState(false)
  const { getTranslatedText } = useContext(ACIHighwayTranslationContext)
 const [lookups, setLookups] = useState(null)
 const fetchCodeMaintenanceLookup = useFetchCodeMaintenanceLookup()

 useEffect(() => {
  Promise.all([
    // Crew Type
    fetchCodeMaintenanceLookup(
      constants.CodeMaintenanceType.CODE_MASTER,
      undefined,
      [{ field: 'codeType', operator: EQUAL, value: 'ACIHW_PARTY_TYPES' }],
      undefined,
      'code'
    )
  ]).then(([aciPartyType]) => {
    const partyMasterConvType = { 'CZ': 'Shipper', 'OS': 'Shipper' };
    const partyType = { ...aciPartyType, ...partyMasterConvType };
    setLookups({ partyType })
  })
 }, [])

  useEffect(() => {
    const fileForUserDetails = FileForUserGetUserDetails();
    if (fileForUserDetails != null && fileForUserDetails != undefined) {
      setValue(getFieldName("fileForUserId"), fileForUserDetails.fileForUserId)
      setValue(getFieldName("fileForUserLoginId"), fileForUserDetails.fileForUserLoginId)
      setValue(getFieldName("fileForUserPartyId"), fileForUserDetails.fileForUserPartyId)
    }
  }, []);

  function getLookupValue(name, value) {
    if (!lookups) return value
    return lookups[name] && lookups[name][value] ? lookups[name][value] : value
  }
  const columns = [
    {
      field: 'partyidn',
      title: getTranslatedText('ACI_HIGHWAY_CARGO', 'partyIdentification')
    },
    {
      field: 'partytype',
      title: getTranslatedText('ACI_HIGHWAY_CARGO', 'partyType'),
     render: (data) =>
        <Typography component='div' variant='inherit' style={{ marginBottom: 4 }}>
          {getLookupValue('partyType', data.partytype)}
        </Typography>
    },
    {
      title: getTranslatedText('ACI_HIGHWAY_CARGO', 'partyName'),
      render: data => [data.partyname1, data.partyname2].join(' ')
    },
    {
      field: 'statecode',
      title: getTranslatedText('ACI_HIGHWAY_CARGO', 'stateCode')
    },
    {
      field: 'countrycode',
      title: getTranslatedText('ACI_HIGHWAY_CARGO', 'countryCode')
    },
  ]

  function handleApplyTemplate(template) {
    if (!template) {
      reset()
      return
    }

    const {
      partytype,
      partyname1,
      address1,
      cityname,
      postalcode,
      countrycode,
      statecode,
      contactperson,
      telephonenumber,
      faxNo,
      email
    } = template

    let partyType = _.isEmpty(partytype) ? '' : partytype.slice(-2)
    const convertPartyType = [{ id: 'SH', value: 'CZ' }, { id: 'SH', value: 'OS' }] //CZ, OS is Shipper in other module

    var matchPartyObj = (convertPartyType.find(obj => {
      return obj.id === partyType || obj.value === partyType;
    }))
    var convPartyType = partyType ? undefined !== matchPartyObj ? matchPartyObj.id : partyType : partyType;

    setValue(getFieldName('partyType'), convPartyType, { shouldDirty: true })
    setValue(getFieldName('partyName'), partyname1 || '', { shouldDirty: true })

    if (partyType !== "CB") {
      setValue(getFieldName('address'), address1 || '', { shouldDirty: true })
      setValue(getFieldName('city'), cityname || '', { shouldDirty: true })
      setValue(getFieldName('postalCode'), postalcode || '', { shouldDirty: true })
      setValue(getFieldName('countryCode'), countrycode || '', { shouldDirty: true })
      setValue(getFieldName('stateCode'), statecode || '', { shouldDirty: true })
      setValue(getFieldName('adminName'), contactperson || '', { shouldDirty: true })
      setValue(getFieldName('adminContact'), telephonenumber || '', { shouldDirty: true })
    } else {
      setValue(getFieldName('phoneNo'), telephonenumber || '', { shouldDirty: true })
      setValue(getFieldName('faxNo'), faxNo || '', { shouldDirty: true })
      setValue(getFieldName('email'), email || '', { shouldDirty: true })
    }
    trigger()
  }

  return (
    <>
      <Grid container spacing={2}>
        <CngGridItem xs={12} lg={4} shouldHide={shouldHideMap?.partyType}>
          <CngCodeMasterAutocompleteField
            required
            name={getFieldName('partyType')}
            key={partyType}
            label={getTranslatedText('ACI_HIGHWAY_CARGO', 'partyType')}
            disabled={disabled}
            codeType='ACIHW_PARTY_TYPES'
            onChange={(value) => {
              if (cargoTypes !== "CSA") {
                setValue(getFieldName('businessNo'), "")
              }
              setValue(getFieldName('partyType'), value)
            }}
            size='small'
          />
        </CngGridItem>
        <CngGridItem xs={12} lg={8}>
          <Grid container spacing={1}>
            <CngGridItem xs={12} sm={4} shouldHide={shouldHideMap?.saveFlag}>
              <CngCheckboxField
                label={
                  <Typography variant='body2' style={{ fontSize: 12, lineHeight: 1.2 }}>
                    {getTranslatedText('ACI_HIGHWAY_CARGO', 'saveFlag')}
                  </Typography>
                }
                name={getFieldName('saveFlag')}
                disabled={disabled}
                onChange={(e) => {
                  setValue(getFieldName('saveFlag'), e.target.checked)

                  if (!e.target.checked) {
                    setValue(getFieldName('partyIdentification'), "")
                  }
                }}
                size='small'
                style={{ marginLeft: 8, marginRight: 8, padding: 8 }}
              />
            </CngGridItem>
            <CngGridItem xs={12} sm shouldHide={shouldHideMap?.partyIdentification}>
              <CngTextField
                required
                name={getFieldName('partyIdentification')}
                inputProps={{
                  style: { textTransform: 'uppercase' },
                  maxLength: 35
                }}
                label={getTranslatedText('ACI_HIGHWAY_CARGO', 'partyIdentification')}
                disabled={disabled || !saveFlag}
                onBlur={(e) => {
                  setValue(getFieldName('partyIdentification'), e.target.value.toUpperCase())
                }}
                size='small'
              />
            </CngGridItem>
            <CngGridItem xs={12} sm={4}>
              <NaAcePartyMasterAutoCompleteField
                name={getFieldName('partyDropDown')}
                label={getTranslatedText('ACI_HIGHWAY_CARGO', 'partyDropDown')}
                disabled={disabled}
                onChange={(_, options) => handleApplyTemplate(options.data)}
                lookupProps={{
                  filters: [{
                    field: 'partyId',
                    operator: EQUAL,
                    value: FileForUserGetPartyId()
                  },
                    { field: 'partytype', operator: IN, value: (partyType === 'CN' ? ['CN'] : partyType === 'SH' ? ['OS', 'CZ', 'SH'] : partyType) } //CZ, OS is Shipper in other module
                  ]
                }}
                size='small'
                fullWidth
                disableClearable
                textFieldProps={{
                  InputProps: {
                    customEndAdornment: () => (
                      <InputAdornment position='end' style={{ marginTop: -16 }}>
                        <IconButton onClick={(event) => {
                          event.stopPropagation()
                          setTemplateDialog(true)
                        }}>
                          <Box display='flex' alignItems='center' justifyContent='center' width={16} height={16}>
                            <FontAwesomeIcon icon={['fal', 'money-check-edit']} size='xs' />
                          </Box>
                        </IconButton>
                      </InputAdornment>
                    )
                  }
                }}
                forcePopupIcon={false}
              />
            </CngGridItem>
          </Grid>
        </CngGridItem>
        <Grid item xs={12}>
          <Grid container spacing={1}>

            {partyType === 'CN' && cargoTypes === 'CSA' && (
              <CngGridItem xs={12} sm={6} md={4} shouldHide={shouldHideMap?.businessNo}>
                <CngTextField
                  required
                  name={getFieldName('businessNo')}
                  label={getTranslatedText('ACI_HIGHWAY_CARGO', 'businessNo')}
                  disabled={disabled}
                  onBlur={(e) => {
                    setValue(getFieldName('businessNo'), e.target.value.toUpperCase())
                  }}
                  size='small'
                />
              </CngGridItem>
            )}

            <CngGridItem xs={12} sm={6} md={4} shouldHide={shouldHideMap?.partyName}>
              <CngTextField
                required
                name={getFieldName('partyName')}
                label={getTranslatedText('ACI_HIGHWAY_CARGO', 'partyName')}
                disabled={disabled}
                inputProps={{ maxLength: 60 }}
                onBlur={(e) => {
                  setValue(getFieldName('partyName'), e.target.value.toUpperCase())
                }}
                size='small'
              />
            </CngGridItem>

            {partyType === 'CB' &&
              <Grid container spacing={1}>
                <CngGridItem xs={12} sm={4} shouldHide={shouldHideMap?.phoneNo}>
                  <CngTextField
                    name={getFieldName('phoneNo')}
                    label={getTranslatedText('ACI_HIGHWAY_CARGO', 'phoneNo')}
                    disabled={disabled}
                    inputProps={{ maxLength: 35 }}
                    onBlur={(e) => {
                      setValue(getFieldName('phoneNo'), e.target.value.toUpperCase())
                    }}
                    size='small'
                  />
                </CngGridItem>
                <CngGridItem xs={12} sm={4} shouldHide={shouldHideMap?.faxNo}>
                  <CngTextField
                    name={getFieldName('faxNo')}
                    label={getTranslatedText('ACI_HIGHWAY_CARGO', 'faxNo')}
                    disabled={disabled}
                    inputProps={{ maxLength: 35 }}
                    onBlur={(e) => {
                      setValue(getFieldName('faxNo'), e.target.value.toUpperCase())
                    }}
                    size='small'
                  />
                </CngGridItem>
                <CngGridItem xs={12} sm={4} shouldHide={shouldHideMap?.email}>
                  <CngTextField
                    name={getFieldName('email')}
                    label={getTranslatedText('ACI_HIGHWAY_CARGO', 'email')}
                    disabled={disabled}
                    inputProps={{ maxLength: 35 }}
                    onBlur={(e) => {
                      setValue(getFieldName('email'), e.target.value.toUpperCase())
                    }}
                    size='small'
                  />
                </CngGridItem>
              </Grid>
            }

            {partyType !== 'CB' &&
              <>
                <CngGridItem xs={12} sm={6} md={4} shouldHide={shouldHideMap?.address}>
                  <CngTextField
                    required={partyType != 'CB' ? true : false}
                    name={getFieldName('address')}
                    label={getTranslatedText('ACI_HIGHWAY_CARGO', 'address')}
                    inputProps={{ maxLength: 200 }}
                    disabled={disabled}
                    onBlur={(e) => {
                      setValue(getFieldName('address'), e.target.value.toUpperCase())
                    }}
                    size='small'
                  />
                </CngGridItem>
                <CngGridItem xs={12} sm={6} md={4} shouldHide={shouldHideMap?.city}>
                  <CngTextField
                    required={partyType != 'CB' ? true : false}
                    name={getFieldName('city')}
                    label={getTranslatedText('ACI_HIGHWAY_CARGO', 'city')}
                    inputProps={{ maxLength: 30 }}
                    disabled={disabled}
                    onBlur={(e) => {
                      setValue(getFieldName('city'), e.target.value.toUpperCase())
                    }}
                    size='small'
                  />
                </CngGridItem>
                <CngGridItem xs={12} sm={6} md={4} shouldHide={shouldHideMap?.postalCode}>
                  <CngTextField
                    required={partyType != 'CB' ? true : false}
                    name={getFieldName('postalCode')}
                    label={getTranslatedText('ACI_HIGHWAY_CARGO', 'postalCode')}
                    inputProps={{ maxLength: 15 }}
                    disabled={disabled}
                    onBlur={(e) => {
                      setValue(getFieldName('postalCode'), e.target.value.toUpperCase())
                    }}
                    size='small'
                  />
                </CngGridItem>
                <CngGridItem xs={12} sm={6} md={4} shouldHide={shouldHideMap?.countryCode}>
                  <CngCountryAutocompleteField
                    required={partyType != 'CB' ? true : false}
                    name={getFieldName('countryCode')}
                    label={getTranslatedText('ACI_HIGHWAY_CARGO', 'countryCode')}
                    disabled={disabled}
                    key={countryCode}
                    onChange={() => {
                      setValue(getFieldName('stateCode'), '')
                    }}
                    lookupProps={{
                      label: (record) => `${record.code};${record.descriptionEn}`
                    }}
                    size='small'
                  />
                </CngGridItem>
                <CngGridItem xs={12} sm={6} md={4} shouldHide={shouldHideMap?.stateCode}>
                  <NaStateCodeAutoCompleteField
                    required={partyType != 'CB' && (countryCode == 'US' || countryCode == 'CA') ? true : false}
                    name={getFieldName('stateCode')}
                    label={getTranslatedText('ACI_HIGHWAY_CARGO', 'stateCode')}
                    disabled={disabled}
                    countryCode={countryCode}
                    key={countryCode + stateCode}
                    size='small'
                  />
                </CngGridItem>
              </>
            }

          </Grid>
        </Grid>

        {partyType !== 'CB' &&
          <Grid item xs={12}>
            <CngSection title={getTranslatedText('ACI_HIGHWAY_CARGO', 'adminstrativeContact')}>
              <Grid container spacing={1}>
                <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.adminName}>
                  <CngTextField
                    name={getFieldName('adminName')}
                    label={getTranslatedText('ACI_HIGHWAY_CARGO', 'adminName')}
                    disabled={disabled}
                    inputProps={{ maxLength: 60 }}
                    onBlur={(e) => {
                      setValue(getFieldName('adminName'), e.target.value.toUpperCase())
                    }}
                    size='small'
                  />
                </CngGridItem>
                <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.adminContact}>
                  <CngTextField
                    name={getFieldName('adminContact')}
                    label={getTranslatedText('ACI_HIGHWAY_CARGO', 'adminContact')}
                    disabled={disabled}
                    inputProps={{ maxLength: 35 }}
                    onBlur={(e) => {
                      setValue(getFieldName('adminContact'), e.target.value.toUpperCase())
                    }}
                    size='small'
                  />
                </CngGridItem>
              </Grid>
            </CngSection>
          </Grid>
        }

      </Grid>

      <TemplateSelectDialog
        columns={columns}
        defaultSorts={[{ field: 'partyidn', direction: 'ASC' }]}
        fetch={{ url: PartyMasterApiUrls.SEARCH }}
        fetchFilters={[
          { field: 'partytype', operator: IN, value: (partyType === 'CN' ? ['CN'] : partyType === 'SH' ? ['OS', 'CZ', 'SH'] : partyType) }, //CZ, OS is Shipper in other module
          { field: 'partyId', operator: EQUAL, value: FileForUserGetPartyId() },
          { field: 'inputSource', operator: EQUAL, value: InputSource.ACI_HWY_CARGO }
        ]}
        onApplyTemplate={handleApplyTemplate}
        onClose={() => setTemplateDialog(false)}
        open={templateDialog}
        search={{ field: 'inputValue', operator: LIKE }}
        title='Party Template'
      />
    </>
  )
}

const CargoPartiesFormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields
})

export default CargoPartiesFormProperties
