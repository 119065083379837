import React, { useEffect, useState } from 'react'
import { useTranslation, useServices, components } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'
import AciHighwayCargoKeys from 'src/constants/locale/key/AciHighwayCargo'
import CngSection from '../../../components/cngcomponents/CngSection'
import CngSubSection from '../../../components/cngcomponents/CngSubSection'
import CngField from '../../../components/cngcomponents/CngField'
import TinyChip from '../../../components/aciacehighway/TinyChip'
import StatusBar from '../../../components/aciacehighway/StatusBar'
import CargoCommodityTable from './CargoCommodityTable'
import CargoCommodityDialog from './CargoCommodityDialog'
import moment from 'moment'
import { Box, Card, Divider, Grid, Typography, useTheme } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBan, faCheckCircle } from '@fortawesome/pro-light-svg-icons'
import AciHighwayCargoApiUrls from 'src/apiUrls/AciHighwayCargoApiUrls'
import History from '../cargo/History'

const {
  button: { CngButton },
  form: {
    field: {
      CngSelectField
    },
  },
  CngGridItem,
} = components

function CargoViewContent(props) {
  const { fetchRecords, securedSendRequest } = useServices()
  const { data, getLookupValue, getCountryStateLabel, manifestId } = props
  const [cargoCommodityDialog, setCargoCommodityDialog] = useState({
    open: false,
    cargoCommodity: null
  })
  const { translate } = useTranslation(Namespace.ACI_HIGHWAY_CARGO)
  const translatedTextsObject = makeTranslatedTextsObject()
  const theme = useTheme()
  const [cargoLoadedInList, setCargoLoadedInList] = useState([]);

  useEffect(() => {
    function onSuccess(response) {
      setCargoLoadedInList(response.data)
    }

    function onError(error) {
      console.log("CargoDialog Error: " + error);
    }

    function onComplete() {

    }
    const config = {}

    securedSendRequest.execute('POST', AciHighwayCargoApiUrls.GET_CARGO_LOADED, { manifestId }, onSuccess, onError, onComplete, config, null);
  }, []);

  function makeTranslatedTextsObject() {
    let cargoLoadedIn = translate(
      Namespace.ACI_HIGHWAY_CARGO,
      AciHighwayCargoKeys.CARGO_LOADED_IN
    )
    let aciHighway = translate(
      Namespace.ACI_HIGHWAY_CARGO,
      AciHighwayCargoKeys.TITLE
    )
    let cargoType = translate(
      Namespace.ACI_HIGHWAY_CARGO,
      AciHighwayCargoKeys.CARGO_TYPE
    )
    let consolidated = translate(
      Namespace.ACI_HIGHWAY_CARGO,
      AciHighwayCargoKeys.CONSOLIDATED
    )
    let carrierCode = translate(
      Namespace.ACI_HIGHWAY_CARGO,
      AciHighwayCargoKeys.CARRIER_CODE
    )
    let ccn = translate(Namespace.ACI_HIGHWAY_CARGO, AciHighwayCargoKeys.CCN)
    let firstExpectedPort = translate(
      Namespace.ACI_HIGHWAY_CARGO,
      AciHighwayCargoKeys.FIRST_EXPECTED_PORT
    )
    let arrivalDateAndTime = translate(
      Namespace.ACI_HIGHWAY_CARGO,
      AciHighwayCargoKeys.ARRIVAL_DATE_AND_TIME
    )
    let arrivalDateAndTimeZone = translate(
      Namespace.ACI_HIGHWAY_CARGO,
      AciHighwayCargoKeys.ARRIVAL_DATE_AND_TIME_ZONE
    )
    let printRemarks = translate(
      Namespace.ACI_HIGHWAY_CARGO,
      AciHighwayCargoKeys.PRINT_REMARKS
    )
    let inbondDestinationOfficeCode = translate(
      Namespace.ACI_HIGHWAY_CARGO,
      AciHighwayCargoKeys.INBOND_DESTINATION_OFFICE_CODE
    )
    let warehouseCode = translate(
      Namespace.ACI_HIGHWAY_CARGO,
      AciHighwayCargoKeys.WAREHOUSE_CODE
    )
    let placeOfLoadingTitle = translate(
      Namespace.ACI_HIGHWAY_CARGO,
      AciHighwayCargoKeys.PLACE_OF_LOADING_TITLE
    )
    let polCity = translate(
      Namespace.ACI_HIGHWAY_CARGO,
      AciHighwayCargoKeys.POL_CITY
    )
    let polCountryCode = translate(
      Namespace.ACI_HIGHWAY_CARGO,
      AciHighwayCargoKeys.POL_COUNTRY_CODE
    )
    let polStateCode = translate(
      Namespace.ACI_HIGHWAY_CARGO,
      AciHighwayCargoKeys.POL_STATE_CODE
    )
    let placeOfAcceptanceTitle = translate(
      Namespace.ACI_HIGHWAY_CARGO,
      AciHighwayCargoKeys.PLACE_OF_ACCEPTANCE_TITLE
    )
    let poaCity = translate(
      Namespace.ACI_HIGHWAY_CARGO,
      AciHighwayCargoKeys.POA_CITY
    )
    let poaCountryCode = translate(
      Namespace.ACI_HIGHWAY_CARGO,
      AciHighwayCargoKeys.POA_COUNTRY_CODE
    )
    let poaStateCode = translate(
      Namespace.ACI_HIGHWAY_CARGO,
      AciHighwayCargoKeys.POA_STATE_CODE
    )
    let adminstrativeContact = translate(
      Namespace.ACI_HIGHWAY_CARGO,
      AciHighwayCargoKeys.ADMINSTRATIVE_CONTACT
    )
    let adminName = translate(
      Namespace.ACI_HIGHWAY_CARGO,
      AciHighwayCargoKeys.ADMIN_NAME
    )
    let adminContact = translate(
      Namespace.ACI_HIGHWAY_CARGO,
      AciHighwayCargoKeys.ADMIN_CONTACT
    )
    let specialInstructions = translate(
      Namespace.ACI_HIGHWAY_CARGO,
      AciHighwayCargoKeys.SPECIAL_INSTRUCTIONS
    )
    let printA8A = translate(
      Namespace.ACI_HIGHWAY_CARGO,
      AciHighwayCargoKeys.PRINT_A8A
    )
    let portOfExit = translate(
      Namespace.ACI_HIGHWAY_CARGO,
      AciHighwayCargoKeys.PORT_OF_EXIT
    )
    let acquittalNo = translate(
      Namespace.ACI_HIGHWAY_CARGO,
      AciHighwayCargoKeys.ACQUITTAL_NO
    )
    let date = translate(Namespace.ACI_HIGHWAY_CARGO, AciHighwayCargoKeys.DATE)
    let cargoParties = translate(
      Namespace.ACI_HIGHWAY_CARGO,
      AciHighwayCargoKeys.CargoParties.TITLE
    )
    let partyType = translate(
      Namespace.ACI_HIGHWAY_CARGO,
      AciHighwayCargoKeys.CargoParties.PARTY_TYPE
    )
    let partyIdentification = translate(
      Namespace.ACI_HIGHWAY_CARGO,
      AciHighwayCargoKeys.CargoParties.PARTY_IDENTIFICATION
    )
    let partyName = translate(
      Namespace.ACI_HIGHWAY_CARGO,
      AciHighwayCargoKeys.CargoParties.PARTY_NAME
    )
    let address = translate(
      Namespace.ACI_HIGHWAY_CARGO,
      AciHighwayCargoKeys.CargoParties.ADDRESS
    )
    let city = translate(
      Namespace.ACI_HIGHWAY_CARGO,
      AciHighwayCargoKeys.CargoParties.CITY
    )
    let postalCode = translate(
      Namespace.ACI_HIGHWAY_CARGO,
      AciHighwayCargoKeys.CargoParties.POSTAL_CODE
    )
    let countryCode = translate(
      Namespace.ACI_HIGHWAY_CARGO,
      AciHighwayCargoKeys.CargoParties.COUNTRY_CODE
    )
    let stateCode = translate(
      Namespace.ACI_HIGHWAY_CARGO,
      AciHighwayCargoKeys.CargoParties.STATE_CODE
    )
    let cargoCommodity = translate(
      Namespace.ACI_HIGHWAY_CARGO,
      AciHighwayCargoKeys.CargoCommodity.TITLE
    )

    let phoneNo = translate(
      Namespace.ACI_HIGHWAY_CARGO,
      AciHighwayCargoKeys.PHONE_NO
    )

    let faxNo = translate(
      Namespace.ACI_HIGHWAY_CARGO,
      AciHighwayCargoKeys.FAX_NO
    )

    let email = translate(
      Namespace.ACI_HIGHWAY_CARGO,
      AciHighwayCargoKeys.EMAIL
    )

    return {
      aciHighway,
      cargoType,
      consolidated,
      carrierCode,
      ccn,
      firstExpectedPort,
      arrivalDateAndTime,
      arrivalDateAndTimeZone,
      printRemarks,
      inbondDestinationOfficeCode,
      warehouseCode,
      placeOfLoadingTitle,
      polCity,
      polCountryCode,
      polStateCode,
      placeOfAcceptanceTitle,
      poaCity,
      poaCountryCode,
      poaStateCode,
      adminName,
      adminContact,
      specialInstructions,
      printA8A,
      adminstrativeContact,
      portOfExit,
      acquittalNo,
      date,
      cargoParties,
      partyType,
      partyIdentification,
      partyName,
      address,
      city,
      postalCode,
      countryCode,
      stateCode,
      cargoCommodity,
      phoneNo,
      faxNo,
      email,
      cargoLoadedIn
    }
  }

  const { cargoType } = data

  const isConsolidated =
    data.consolidated === 'Y' ? true : data.consolidated === 'N' && false

  const isPaperRelease =
    data.paperRelease === 'YES' ? true : data.paperRelease === 'NO' && false

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <StatusBar status={data.status} />
        </Grid>
        <Grid item xs={12}>
          <Grid container justify='space-between' spacing={3}>
            <Grid item xs={12} md='auto'>
              <Typography variant='body2'>
                The following shipment information was updated on{' '}
                <strong>
                  {moment(data.updatedDate).format('dddd, D MMM YYYY, HH:mm')}
                </strong>
              </Typography>
            </Grid>
            <Grid item xs={12} md='auto'>
              <Typography variant='body2' style={{ fontWeight: 700 }}>
                Document ref. no.: {data.acquittalNo || '-'}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <CngSection title={translatedTextsObject.aciHighway}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={4}>
                    <CngField label={translatedTextsObject.cargoType}>
                      {data.cargoType}
                    </CngField>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <CngField label={translatedTextsObject.consolidated}>
                      {isConsolidated ? 'Yes' : 'No'}
                    </CngField>
                  </Grid>
                  {cargoType !== 'OSC' && (
                    <Grid item xs={12} sm={6} md={4}>
                      <CngField>
                        <Box display='flex' alignItems='center' style={{ gap: 8 }}>
                          <FontAwesomeIcon
                            color={
                              isPaperRelease
                                ? theme.palette.primary.main
                                : theme.palette.error.main
                            }
                            icon={isPaperRelease ? faCheckCircle : faBan}
                          />
                          <Typography variant='body2'>Paper release</Typography>
                        </Box>
                      </CngField>
                    </Grid>
                  )}
                  <Grid item xs={12} sm={6} md={4}>
                    <CngField label={translatedTextsObject.carrierCode}>
                      {data.carrierCode}
                    </CngField>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <CngField label={translatedTextsObject.ccn}>
                      {data.ccn}
                    </CngField>
                  </Grid>
                  {cargoType !== 'OSC' && (
                    <>
                      <Grid item xs={12} sm={6} md={4}>
                        <CngField label={translatedTextsObject.firstExpectedPort}>
                          <Typography component='div' variant='inherit'>
                            {getLookupValue('firstExpectedPort', data.firstExpectedPort)}
                            <TinyChip label={data.firstExpectedPort} variant='outlined' />
                          </Typography>
                        </CngField>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <CngField label={translatedTextsObject.arrivalDateAndTime}>
                          {data.arrivalDateAndTime &&
                            moment(data.arrivalDateAndTime).format('D MMM YYYY, HH:mm')}
                        </CngField>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <CngField label={translatedTextsObject.arrivalDateAndTimeZone}>
                          {data.arrivalDateAndTimeZone && (
                            <Typography component='div' variant='inherit'>
                              {getLookupValue('arrivalDateAndTimeZone', data.arrivalDateAndTimeZone)}
                              <TinyChip label={data.arrivalDateAndTimeZone} variant='outlined' />
                            </Typography>
                          )}
                        </CngField>
                      </Grid>
                    </>
                  )}
                  {cargoType !== 'OSC' && cargoType === 'IN-BOND' && (
                    
                      <Grid item xs={12} sm={6} md={4}>
                        <CngField label={translatedTextsObject.inbondDestinationOfficeCode}>
                          {data.inbondDestinationOfficeCode && (
                            <Typography component='div' variant='inherit'>
                              {getLookupValue('sublocation', data.inbondDestinationOfficeCode)}
                              <TinyChip label={data.inbondDestinationOfficeCode} variant='outlined' />
                            </Typography>
                          )}
                        </CngField>
                      </Grid>
                  )}
                  {((cargoType !== 'OSC' && cargoType === 'IN-BOND') || (cargoType !== 'IN-BOND' && isConsolidated === true)) && (
                      <Grid item xs={12} sm={6} md={4}>
                        <CngField label={translatedTextsObject.warehouseCode}>
                          {data.warehouseCode && (
                            <Typography component='div' variant='inherit'>
                              {getLookupValue('sublocation', data.warehouseCode)}
                              <TinyChip label={data.warehouseCode} variant='outlined' />
                            </Typography>
                          )}
                        </CngField>
                      </Grid>
                  )}
                  {cargoType !== 'OSC' && (
                    <Grid item xs={12} sm={6} md={4}>
                      <CngField label={translatedTextsObject.printRemarks}>
                        {data.printRemarks}
                      </CngField>
                    </Grid>
                  )}
                  {cargoType !== 'OSC' && (
                    <Grid item xs={12} sm={6} md={4}>
                      <CngField label={'Cargo Loaded In'}>
                        <Typography component='div' variant='inherit'>
                          {
                            cargoLoadedInList
                              .filter((loaded) => loaded.value === data.cargoLoadedIn)
                              .map((loaded) => (
                                loaded.text
                              ))
                          }
                        </Typography>
                      </CngField>
                    </Grid>
                  )}
                  {cargoType !== 'CSA' && (
                    <Grid item xs={12} sm={6} md={4}>
                      <CngField label={'Entry Number'}>
                        {data.entryNo}
                      </CngField>
                    </Grid>
                  )}
                </Grid>
              </Grid>
              {cargoType !== 'OSC' && (
                <>
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Grid alignItems='center' container spacing={2}>
                          <Grid item xs={12} sm='auto'>
                            <Typography variant='body2' style={{ fontWeight: 600 }}>
                              {translatedTextsObject.placeOfLoadingTitle}
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm>
                            <Divider />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={6} md={4}>
                            <CngField label={translatedTextsObject.polCity}>
                              {data.polCity}
                            </CngField>
                          </Grid>
                          <Grid item xs={12} sm={6} md={4}>
                            <CngField label={translatedTextsObject.polCountryCode}>
                              <Typography component='div' variant='inherit'>
                                {getLookupValue('countries', data.polCountryCode)}
                                <TinyChip label={data.polCountryCode} variant='outlined' />
                              </Typography>
                            </CngField>
                          </Grid>
                          <Grid item xs={12} sm={6} md={4}>
                            <CngField label={translatedTextsObject.polStateCode}>
                              {data.polStateCode && (
                                <Typography component='div' variant='inherit'>
                                  {getCountryStateLabel(data.polCountryCode, data.polStateCode)}
                                  <TinyChip label={data.polStateCode} variant='outlined' />
                                </Typography>
                              )}
                            </CngField>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <CngSubSection title={translatedTextsObject.placeOfAcceptanceTitle}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={6} md={4}>
                          <CngField label={translatedTextsObject.poaCity}>
                            {data.poaCity}
                          </CngField>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                          <CngField
                            label={translatedTextsObject.poaCountryCode}
                          >
                            {data.poaCountryCode && (
                              <Typography component='div' variant='inherit'>
                                {getLookupValue('countries', data.polCountryCode)}
                                <TinyChip label={data.polCountryCode} variant='outlined' />
                              </Typography>
                            )}
                          </CngField>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                          <CngField label={translatedTextsObject.poaStateCode}>
                            {data.poaStateCode && (
                              <Box display='flex' alignItems='center' style={{ gap: 8 }}>
                                <Typography component='div' variant='inherit'>
                                  {getCountryStateLabel(data.poaCountryCode, data.poaStateCode)}
                                  <TinyChip label={data.poaStateCode} variant='outlined' />
                                </Typography>
                              </Box>
                            )}
                          </CngField>
                        </Grid>
                      </Grid>
                    </CngSubSection>
                  </Grid>
                  <Grid item xs={12}>
                    <CngSubSection title={translatedTextsObject.adminstrativeContact}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={6} md={4}>
                          <CngField label={translatedTextsObject.adminName}>
                            {data.adminName}
                          </CngField>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                          <CngField label={translatedTextsObject.adminContact}>
                            {data.adminContact}
                          </CngField>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                          <CngField label={translatedTextsObject.specialInstructions}>
                            {data.specialInstructions}
                          </CngField>
                        </Grid>
                      </Grid>
                    </CngSubSection>
                  </Grid>
                  <Grid item xs={12}>
                    <CngSubSection title={translatedTextsObject.printA8A}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={6} md={4}>
                          <CngField label={translatedTextsObject.portOfExit}>
                            {data.portOfExit}
                          </CngField>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                          <CngField label={translatedTextsObject.acquittalNo}>
                            {data.acquittalNo}
                          </CngField>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                          <CngField label={translatedTextsObject.date}>
                            {data.date && moment(data.date).format('D MMM YYYY, HH:mm')}
                          </CngField>
                        </Grid>
                      </Grid>
                    </CngSubSection>
                  </Grid>
                </>
              )}
            </Grid>
          </CngSection>
        </Grid>
        <Grid item xs={12}>
          <CngSection title={translatedTextsObject.cargoParties}>
            {data.cargoParties.length > 0 ? (
              <Grid container spacing={2}>
                {data.cargoParties.map(cargoParty => (
                  <Grid key={cargoParty.id || cargoParty._id} item xs={12}>
                    <Card variant='outlined' style={{ backgroundColor: theme.palette.grey[50] }}>
                      <Box padding={2}>
                        <Grid container spacing={2}>
                          <Grid item xs={12} md={4}>
                            <CngField label={getLookupValue('partyType', cargoParty.partyType)}>
                              {cargoParty.partyName}
                            </CngField>
                          </Grid>
                          <Grid item xs={12} md={8}>
                            <Grid container spacing={2}>
                              {cargoParty.partyIdentification && (
                                <Grid item xs={12} sm={6}>
                                  <CngField label={translatedTextsObject.partyIdentification}>
                                    {cargoParty.partyIdentification}
                                  </CngField>
                                </Grid>
                              )}
                              {cargoParty.partyType === "CB" && <>
                                <Grid item xs={12} sm={6}>
                                  <CngField label={translatedTextsObject.phoneNo}>
                                    {cargoParty.phoneNo}
                                  </CngField>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                  <CngField label={translatedTextsObject.faxNo}>
                                    {cargoParty.faxNo}
                                  </CngField>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                  <CngField label={translatedTextsObject.email}>
                                    {cargoParty.email}
                                  </CngField>
                                </Grid>
                              </>
                              }
                              {cargoParty.partyType !== "CB" && <>
                                <Grid item xs={12} sm={6}>
                                  <CngField label={translatedTextsObject.address}>
                                    {cargoParty.address}
                                  </CngField>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                  <CngField label={translatedTextsObject.city}>
                                    {cargoParty.city}
                                  </CngField>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                  <CngField label={translatedTextsObject.postalCode}>
                                    {cargoParty.postalCode}
                                  </CngField>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                  <CngField label={translatedTextsObject.countryCode}>
                                    {cargoParty.countryCode && (
                                      <Typography component='div' variant='inherit'>
                                        {getLookupValue('countries', cargoParty.countryCode)}
                                        <TinyChip label={cargoParty.countryCode} variant='outlined' />
                                      </Typography>
                                    )}
                                  </CngField>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                  <CngField label={translatedTextsObject.stateCode}>
                                    {cargoParty.stateCode && (
                                      <Typography component='div' variant='inherit'>
                                        {getCountryStateLabel(cargoParty.countryCode, cargoParty.stateCode)}
                                        <TinyChip label={cargoParty.stateCode} variant='outlined' />
                                      </Typography>
                                    )}
                                  </CngField>
                                </Grid>
                              </>}
                            </Grid>
                          </Grid>
                          {cargoParty.partyType !== "CB" && <>
                            <Grid item xs={12}>
                              <CngSection title={translatedTextsObject.adminstrativeContact}>
                                <Grid container>
                                  <Grid item xs={12} sm={4} />
                                  <Grid item xs={12} sm={8}>
                                    <Grid container spacing={2}>
                                      <Grid item xs={12} sm={6}>
                                        <CngField label={translatedTextsObject.adminName}>
                                          {cargoParty.adminName}
                                        </CngField>
                                      </Grid>
                                      <Grid item xs={12} sm={6}>
                                        <CngField label={translatedTextsObject.adminContact}>
                                          {cargoParty.adminContact}
                                        </CngField>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </CngSection>
                            </Grid></>}
                        </Grid>
                      </Box>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            ) : (
              <Typography variant='body2' color='textSecondary'>
                No records to display
              </Typography>
            )}
          </CngSection>
        </Grid>
        <Grid item xs={12}>
          <CngSection title={translatedTextsObject.cargoCommodity}>
            <CargoCommodityTable
              cargoCommodity={data.cargoCommodity}
              onViewCargoCommodity={(cargoCommodity) =>
                setCargoCommodityDialog({ open: true, cargoCommodity })
              }
            />
          </CngSection>
        </Grid>
        <Grid item xs={12}>
          <CngSection title="History">
            <History cargoId={data.id} />
          </CngSection>
        </Grid>
      </Grid>
      <CargoCommodityDialog
        cargoCommodity={cargoCommodityDialog.cargoCommodity}
        isView
        hazardousMaterial={cargoCommodityDialog.cargoCommodity?.hazardousMaterial}
        open={cargoCommodityDialog.open}
        onClose={() => setCargoCommodityDialog({ open: false, cargoCommodity: null })}
      />
    </>
  )
}

export default CargoViewContent
